<template>
  <b-modal
    id="modal-operational-time"
    size="md"
    centered
    scrollable
    hide-footer
  >
    <template #modal-header>
      <h5 class="modal-title">Jam Operasional</h5>
      <button class="btn-close-modal-custom" @click="$bvModal.hide('modal-operational-time')">&times;</button>
    </template>
    <div class="d-flex align-items-center mb-2">
      <b-img width="40" class="mx-lg-2 mx-3" :src="require('@/assets/img/icon_time_warning.svg')" alt=""></b-img>
      <span class="text-grey">Pesanan kamu berpontensi mengalami keterlambatan pengiriman jika diluar jam operasional.</span>
    </div>
    <div v-for="(opTime, index) in operationalTime" :key="index" class="operational-time-list">
      <span>{{ opTime.name }}</span>
      <span class="float-right">{{ setOperationalTimeText(opTime) }}</span>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalOperationalTime",
  props: ["operationalTime"],
  methods: {
    setOperationalTimeText(currOpTime) {
      if (!currOpTime.open && !currOpTime.closed) return "Libur";
      else if (currOpTime.open == "00:00" && currOpTime.closed == "23:59") return "Buka 24 Jam";
      else return `${currOpTime.open} - ${currOpTime.closed} WIB`
    }
  }
}
</script>